.fileViewerTextGrid {
    max-width: 900px;
    padding: 10px;
    /* background-color: var(--ion-background-color, #fff); */
}

/* .fileViewerContent {
    --background: #F8F8FF;
} */

.fileViewerTextSelectable {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.fileViewerProcessingAnimationDiv {
    padding: 20px;
}

.fileViewerProcessingAnimationParagraph {
    text-align: center;
}

.fileViewerPageDiv {
    flex: 1;
    white-space: pre-wrap;
}

.fileViewerTextarea {
    font-size: 18px;
    font-weight: bold;
    border: none;
    outline: none;
    height: fit-content;
    resize: none;
}

.fileViewerTutorialToast {
    --border-radius: 50px;
    --border-width: 3px;
    --border-style: solid;
    --border-color: #000000;
    --background: #1d69c4;
    opacity: 0.9;
    text-align: center;
    font-weight: bold;
    font-size: large;

}

.fileViewerAudioPlayer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.fileViewerImageAlert {
    --backdrop-opacity: 1;
    --max-width: 600px;
    --max-height: 600px;
}

@media only screen and (max-device-width: 480px) {
    .fileViewerImageAlert {
        --max-width: 200px;
        --max-height: 200px;
    }
}

.fileViewerTutorialDiv {
    position: absolute;
    top: 20vh;
    left: 10vh;
}