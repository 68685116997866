.dictionaryWord {
    text-align: center;
    flex: 1;
}

.dictionaryWordAndTTSDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dictionaryWordTTSButton {
    margin-left: auto;
    margin-right: 0;
}

.dictionaryMeaningsDiv {
    max-height: 300px;
    overflow-y: scroll;
    scrollbar-width: 7px;
    padding-right: 10px;
}

@media (prefers-color-scheme: light) {
    .dictionaryMeaningsDiv {
        scrollbar-color: var(--ion-color-medium-tint) black;
    }

    .dictionaryMeaningsDiv::-webkit-scrollbar {
        background: black;
        /* color: yellow; */
        width: 7px;
        /* height: 10px; */
    }
    
    .dictionaryMeaningsDiv::-webkit-scrollbar-thumb {
        background: var(--ion-color-medium-tint);
        border-radius: 10px;
        -webkit-border-radius: 10px;
    }
}
  
@media (prefers-color-scheme: dark) {
    .dictionaryMeaningsDiv {
        scrollbar-color: var(--ion-color-step-500) white;
    }

    .dictionaryMeaningsDiv::-webkit-scrollbar {
        background: white;
        width: 7px;
    }
    
    .dictionaryMeaningsDiv::-webkit-scrollbar-thumb {
        background: var(--ion-color-step-500);
        border-radius: 10px;
        -webkit-border-radius: 10px;
    }
}

.dictionaryMeaningDiv {
    text-align: start;
}

.dictionaryDefinitionDiv {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid gray;
    text-align: start;
}

.dictionarySynonymsAntonymsDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.dictionarySynonymsDiv {
    flex: 1;
}

.dictionaryAntonymsDiv {
    flex: 1;
}

.dictionaryWordChip {
    --background: var(--ion-color-dark-contrast);
}

@media (prefers-color-scheme: light) {
    .dictionaryWordTTSButton {
        --color: white;
    }
}
  
@media (prefers-color-scheme: dark) {
    .dictionaryWordTTSButton {
        --color: black;
    }
}

.dictionaryLoadingTtsSpinner {
    height: 20px;
    width: 20px;
}