.onboardingSlide {
    margin-top: 5vh;
    margin-bottom: 20px;
}

.onboardingSlideGrid {
    display: flex;
    flex-direction: column;
}

.onboardingSlideAnimationRow {
    display: flex;
    justify-content: center;
}

.onboardingSlideHeaderRow {
    flex: 1;
    display: flex;
    justify-content: center;
}

.onboardingSlideHeader {
    flex: 1;
    text-align: center;
}

.onboardingSlideBodyRow {
    flex: 1;
    display: flex;
    justify-content: center;
}

.onboardingSlideBodyParagraph {
    flex: 1;
    text-align: center;
}

.onboardingSlideCTAButtonRow {
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
}

.onboardingSlideCTAButton {
    text-transform: none;
}