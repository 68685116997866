.onboardingGrid {
    margin-top: 10px;
    /* max-width: 500px; */
    margin-left: 10px;
    margin-right: 10px;
}

.onboardingHeader {
    text-align: center;
    font-size: 35px;
}

.onboardingLogosDiv {
    position: absolute;
    left: 20px;
    bottom: 20px;
}

.onboardingUnitusLogoImg {
    width: 10%;
    min-width: 100px;
}