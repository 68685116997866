.fileManagerFileName {
    text-align: center;
    padding-bottom: 0;
}

.fileManagerSearchBar {
    padding-bottom: 0 !important;
    max-width: 80%;
}

.fileManagerTitle {
    display: inline-block;
    vertical-align: middle;
}

.fileManagerItemsContainer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -moz-box-wrap: wrap;
    -webkit-box-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flexbox-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.fileManagerItem {
    box-sizing: border-box;
    /* max-width: 100px; */
}

.filesManagerAddFab {
    margin-bottom: 1px;
}

.filesManagerUploadCTAParagraph {
    text-align: center;
    flex: 1;
    margin-top: 60px;
    color: gray;
}

.filesManagerTapToUploadAnimation {
    position: absolute;
    bottom: -10px;
    right: -20px;
    transform: rotate(140deg);
}

.fileManagerFolderCreationIcon {
    font-size: 30px;
    text-align: center;
}

.filesManagerAddFab input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.fileManagerPopoverContext {
    transform: translateX(30px);
}

.fileManagerSegmentButton {
    max-width: fit-content;
    text-transform:none!important;
    --indicator-color: transparent !important;
    --indicator-color-checked: transparent !important;
  }

.filesManagerDropzoneActive {
    background: linear-gradient(90deg,gray 50%, transparent 50%), linear-gradient(90deg, gray 50%, transparent 50%), linear-gradient(0deg, gray 50%, transparent 50%), linear-gradient(0deg, gray 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
    padding: 10px;
    animation: border-dance 20s infinite linear;
    opacity: 0.7;
  }
  
  @keyframes border-dance {
    0% {
      background-position: 0 0, 100% 100%, 0 100%, 100% 0;
    }
    100% {
      background-position: 100% 0, 0 100%, 0 0, 100% 100%;
    }
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(3deg); }
   95% { transform: rotate(-3deg); }
  100% { transform: rotate(0deg); }
}

div.filesManagerNotificationsDivWiggle {
    animation: wiggle 2s infinite;
    margin-top: 10px;
    margin-bottom: 10px;
}

div.filesManagerNotificationsDivWiggle:hover {
    animation: none;
}

.filesManagerNotificationsAlertGrid {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.filesManagerNotificationsIllustration {
    width: calc(100vw / 3);
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
}

.filesManagerCardSizeItem{
    --background:'transparent'
}

.filesManagerNotificationsCTAText {
    flex: 1;
    text-align: center;
    margin-top: 5px;
}