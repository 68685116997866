.settingsGrid {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
}

.settingsRow {
    flex: 1;
}

.settingsCard {
    flex: 1;
}

.settingsHighlightItem {
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid lightgray;
    margin-bottom: 10px;
}

.settingsHighlightItemGrid {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.settingsHighlightingMainDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-overflow: ellipsis;
}

.settingsHighlightPlaceholder{
    font-size: 25px;
    position: absolute;
    opacity: .5;
    margin-left: 6px;
    margin-right: 15px;
    z-index: -2;
    max-width: 500px !important;
    text-overflow: ellipsis;
}
@media only screen and (max-device-width: 480px) {
    .settingsHighlightPlaceholder{
        max-width:200px !important;
    }

}
.settingsHighlightItemGridRow {
    flex: 1;
}

.settingsHighlightItemGridColorRow {
    flex: 1;
    justify-content: center;
}

.settingsHighlightItemGridRemoveRow {
    flex: 1;
    justify-content: flex-end;
}

.settingsColorDiv {
    width: 30px;
    height: 30px;
    border-radius: 15px;
}

.settingsDocumentViewingItemDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.settingVoicePreviewButtonIcon {
    margin-right: 10px;
}

.settingsDocumentViewingItemPreviewDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.settingsDocumentViewingParagraph {
    flex: 1;
    font-weight: bold !important;
}

.settingsDocumentViewingFontPicker {
    min-width: 97% !important;
    padding-left: 15px;
}

.settingsDocumentViewingPreviewHeaderParagraph {
    flex: 1;
    font-weight: bold !important;
    margin-bottom: 20px !important;
}

.settingsNumericInput{
    text-align: center;
}

.settingsNumericInput > input[type=number] {
    -moz-appearance: textfield;
}

.settingsNumericInput > input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.settingsDocumentViewingButtonsDiv {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.settingsDocumentViewingPreviewParagraph {
    color: black;
    background-color: #fcf3e6;
    border-radius: 5px;
    padding: 5px;
    overflow: hidden;
    margin-bottom: 20px !important;
}

.settingsEasyReadingAttributionDiv {
    margin-top: 10px;
}

.settingsEasyReadingAttributionParagraph {
    text-align: center;
}

.settingsLicensesButtonDiv {
    display: flex;
    justify-content: center;

    margin-top: 20px;
    margin-bottom: 20px;
}

.settingsLicensesButton {
    text-transform: none;
}