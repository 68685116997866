.magicLinkButtonsGrid {
    display: grid;
    justify-content: center;
}

.magicLinkTitle {
    display: grid;
    justify-content: center;
    font-size: 35px;
    font-family: 'Easy Reading';
}

.authenticationCompletionTitle {
    margin: 5vh;
}

.authenticationCompletionImage {
    align-self: center;
    width: fit-content;
}

.authenticationCompletionOuterDiv {
    display: flex;
    height: 100%;
    justify-content: center;
}

.authenticationCompletionInsertEmailDiv {
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.authenticationCompletionInsertEmailInput {
    border: 1px solid black;
    border-radius: 15px;
    width: 50%;
    align-self: center;
}

.authenticationCompletionConfirmEmailDiv {
    display: flex;
    margin: 3vh;
    justify-content: center;
}

.authenticationCompletionInnerDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.authenticationCompletionErrorText {
    font-size: x-large;
}

@media only screen and (min-width : 320px) {
    .magicLinkTitle {
        font-size: 15px;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
    .magicLinkTitle {
        font-size: 20px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .magicLinkTitle {
        font-size: 25px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .magicLinkTitle {
        font-size: 35px;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .magicLinkTitle {
        font-size: 35px;
    }
}

@media (prefers-color-scheme: dark) {
    .devicesIcon {
        filter: invert(100%);
    }
}