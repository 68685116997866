.selectionPopoverOuterDiv {
    position: absolute;
    top: -100px;
    visibility: hidden;
    /* display: block;
    margin-left: auto;
    margin-right: auto; */
    /* background-color: red; */
    width: 100%;
    pointer-events: none;
}

.selectionPopoverInnerDiv {
    position: relative;
    width: 80%;
    max-width: 400px;
    pointer-events: visible;
    
    /* margin-left: -60px; */
    /* display: block; */
    /*margin: 0 auto; */
    
    text-align: center;
    padding: 15px;
    padding-bottom: 10px;
    border-radius: 6px;

    -webkit-box-shadow: 0px 10px 28px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 28px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 28px -6px rgba(0,0,0,0.75);
}

.selectionPopoverInnerDiv::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
}

.selectionPopoverNavigateBackDiv {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 10;
    height: 40px;
    width: 40px;

    background: #AAAAAA80;
    border-radius: 40px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0;
}

.selectionPopoverNavigateBackIcon {
    position: absolute;
    color: white;
}

@media (prefers-color-scheme: light) {
    .selectionPopoverInnerDiv {
        background: black;
        color: white;
    }
    .selectionPopoverInnerDiv::after {
        border-color: transparent transparent black transparent;
    }
    .selectionPopoverNavigateBackDiv:hover {
        background: #DDDDDD80;
    }
}
  
@media (prefers-color-scheme: dark) {
    .selectionPopoverInnerDiv {
        background: white;
        color: black;
    }
    .selectionPopoverInnerDiv::after {
        border-color: transparent transparent white transparent;
    }
    .selectionPopoverNavigateBackDiv:hover {
        background: #55555580;
    }
}