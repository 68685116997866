.clickPopoverMainClass {
    --width: 90vw;
    --max-width: 700px;

    --height: 50vh;

    --backdrop-opacity: 0.8;
}

.clickPopoverInnerDiv {
    margin: 10px;
}

.clickPopoverHeaderParagraph {
    font-size: x-large;
    text-align: center;
    font-weight: bold;
}

.clickPopoverTextDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    overflow-y: scroll !important;
}

.clickPopoverTextElement {
    width: fit-content;

    border-radius: 4px;
    overflow: hidden;

    color: black;
    background-color: #F8F8FF;

    margin: 0;
    margin-right: 15px;
    margin-bottom: 10px;
    padding: 5px;

    cursor: pointer !important;

    font-size: x-large;
}

.clickPopoverWordActionsDiv {
    padding: 10px;
}

.clickPopoverWordDictionaryDiv {
    margin-top: 25px;
    padding: 10px;
}