.wordActionsSearchWordImagesDiv {
    position: relative;
    height: 200px;

    overflow-x: scroll;
    overflow-y: hidden;

    white-space: nowrap;
}

.wordActionImagesRow {
    height: 200px;

    overflow-x: auto;
    overflow-y: hidden;

    white-space: nowrap;
}

.wordActionsImage {
    display: inline-block;
    overflow-y: hidden;
    height: 90%;
    width: 60%;

    margin-left: 5px;
    margin-right: 5px;

    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
}

.wordActionsWordParagraph {
    text-align: center;
}

@media (prefers-color-scheme: light) {
    .wordActionsActionButton {
        --background: gray;
    }
}

@media (prefers-color-scheme: dark) {
    .wordActionsActionButton {
        --background: #333;
    }
}

.wordActionsActionParagraph {
    padding-left: 10px;
}