.authGetStartedMainGrid {
    max-width: 500px;

    height: 99%;
    display: flex;
    flex-direction: column;
}

.authGetStartedTextDiv {
    flex: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.authGetStartedWelcomeText {
    font-size: x-large;
    text-align: center;
}

.authGetStartedSubTitle {
    text-align: center;
    margin-top: -15px;
    font-size: larger;
    margin-bottom: 5vh;
}

.c {
    font-size: large;
    text-align: center;
}

.authGetStartedIllustrationDiv {
    flex: 2;
    max-height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.authGetStartedIllustrationDiv > h1 {
    font-size: 50px;
    font-weight: bold;
}

@keyframes wiggleHandAnimation {
    0% {
        transform: rotate(0deg);
    }

    80% {
        transform: rotate(0deg);
    }

    85% {
        transform: rotate(5deg);
    }

    95% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.authGetStartedWavingHandImg {
    object-fit: contain;

    height: 50%;
    width: 50%;

    animation: wiggleHandAnimation 1s;
    animation-iteration-count: 2;
}

.authGetStartedButtonsDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.authGetStartedEmailAndPasswordButton {
    text-transform: none;
}

.authGetStartedMagicLinkButton {
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: none;

    --background: #3626A7;
}

.authGetStartedMagicLinkButtonIcon {
    margin-right: 10px;
}