.magicLinkRequestMainGrid {
    max-width: 500px;


    display: flex;
    flex-direction: column;

    padding: 10px;

    height: 99%;
}

.magicLinkRequestTitle {
    text-align: center;
    font-size: xx-large;
    margin-bottom: 5vh;
    margin-top: 5vh;
}

.magicLinkRequestMainTextDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.magicLinkRequestHintParagraph {
    text-align: center;
}

.magicLinkRequestEmailAndTermsDiv {
    flex: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 4vh;

}

.magicLinkRequestLottieDiv {
    margin-bottom: 10vh;
}

.magicLinkRequestEmailInput {
    text-align: center;

    --padding-start: 15px;
    --padding-end: 15px;

    border: 1px solid gray;
    border-radius: 10px;
    overflow: hidden;
}

.magicLinkRequestToggleAndTermsDiv {
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.magicLinkRequestToggle {
    margin-right: 10px;
    --handle-background-checked: #3626A7;
}

.magicLinkRequestTermsParagraph {
    font-size: small;
}

.magicLinkRequestSendEmailDiv {
    flex: 1;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.magicLinkRequestSendEmailButton {
    text-transform: none;
    --background: #3626A7;
}

.magicLinkRequestConfirmationDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.magicLinkRequestConfirmationParagraph {
    text-align: center;
    font-size: medium;
}

.magicLinkRequestConfirmationCTAParagraph {
    text-align: center;
    font-size: larger;

    font-weight: bold;
}