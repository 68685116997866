.drawsAndImagesInnerDiv {
    margin-top: 20px;

    height: 25vh;

    overflow-x: auto;
    overflow-y: hidden;

    white-space: nowrap;
}

.drawsAndImagesImg {
    display: inline-block;
    overflow-y: hidden;
    height: 90%;
    width: 60% !important;

    margin-left: 5px;
    margin-right: 5px;

    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
}

.drawAndImagesNoDrawsOrImagesParagraph {
    padding: 20px;
    margin-top: 40px;

    text-align: center;
    color: gray;
}