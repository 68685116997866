.colorPickerPopover {
    --height: auto !important;
    --width: auto !important;
}

.colorPickerPopoverTitle {
    text-align: center;
}

.colorPickerPopoverColorsDiv {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.colorPickerPopoverColorButton {
    height: auto;
    --background-activated: transparent;
    --background-hover: transparent;
    --background-focused: transparent;
    --ripple-color: transparent;
    --background-activated-opacity: 0;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
}

@media (prefers-color-scheme: light) {
    .colorPickerPopoverColorItemDiv {
        width: 10vh;
        height: 10vh;
        border-radius: 100%;
        border: 0px;
        background-color: red;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        opacity: 0.5;
    }

    .colorPickerPopoverSelectedColorItemDiv {
        width: 10vh;
        height: 10vh;
        border-radius: 100%;
        border: 5px solid black;
        background-color: red;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        opacity: 1;
    }
}

@media (prefers-color-scheme: dark) {
    .colorPickerPopoverColorItemDiv {
        width: 10vh;
        height: 10vh;
        border-radius: 100%;
        border: 0px;
        background-color: red;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        opacity: 0.5;
    }

    .colorPickerPopoverSelectedColorItemDiv {
        width: 10vh;
        height: 10vh;
        border-radius: 100%;
        border: 5px solid white;
        background-color: red;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        opacity: 1;
    }
}


.colorPickerPopoverColorNameParagraph {
    flex: 1;
    text-align: center;
}

.colorPickerPopoverEndButtonDiv {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
}

.colorPickerPopoverResetButtonDiv {
    display: flex;
    justify-content: flex-start;
    margin: 10px;
}

@media only screen and (min-width: 320px) {
    .colorPickerPopover {
        --width: 90% !important;
        --height: auto !important;
    }

    .colorPickerPopoverTitle {
        font-size: 20px;
    }

    .colorPickerPopoverColorsDiv {
        scale: .9;
        width: 100%;
        align-content: flex-start;
    }

    .colorPickerPopoverColorButton {
        margin: -10px;
    }

    .colorPickerPopoverEndButtonDiv {
        margin-top: 0;
        margin-right: 10px;
    }

    .colorPickerPopoverResetButtonDiv {
        margin-top: 0;
        margin-left: 10px;
    }
}

@media only screen and (min-width: 600px) {

    /* For tablets: */
    .colorPickerPopover {
        --width: fit-content !important;
    }
}

@media only screen and (min-width: 768px) {

    /* For desktop: */
    .colorPickerPopover {
        --width: fit-content !important;
    }
}