.profileGrid {
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

.profileUserDataDiv {
    display: flex;
    flex-direction: column;
}

.profileUserDataRow {
    flex: 1;
    margin-bottom: 10px;
}

.profileChangePasswordButtonRow {
    flex: 1;
    justify-content: center;
}

.userSettingsDevicesCardContent {
    display: flex;
    flex-direction: column;
}

.userSettingsDeviceDiv {
    flex: 1;
    
    display: flex;
    flex-direction: row;
    align-items: center;
}

.userSettingsNotificationDeviceOsDiv {
    margin-right: 15px;
}

.userSettingsDeviceActiveToggleDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.userSettingsDeviceActiveToggleLabel {
    font-size: xx-small !important;
    color: gray;
}

.profileSupportGrid {
    display: flex;
    flex-direction: column;
}

.profileSupportRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    cursor: pointer;
}

.profileSupportIcon {
    flex: 1;
}

.profileSupportLabel {
    flex: 5
}

.profileLogoutDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}